<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="营业时间">
        <el-date-picker value-format="YYYY-MM" v-model="data.time" type="month" clearable />
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCode"
          :options="areaOptions"
          clearable
          filterable
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCode"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 250px;"
          multiple
          filterable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>

        <el-button @click="resetData">
          重置
        </el-button>
        <el-upload
          :action="uploadAction"
          :on-preview="handlePreview"
          v-if="data.authMenus.import"
          :data="{ type: 'COST_DETAILS' }"
          :headers="{
            Authorization: 'Bearer ' + getToken('access_token'),
          }"
          :on-success="handleSuccess"
          :on-error="handleError"
          :show-file-list="false"
          style="margin: 0px 10px;"
        >
          <el-button type="primary">上传成本项</el-button>
        </el-upload>
        <el-button @click="downTemplate" type="primary" v-if="data.authMenus.downloadTemplate">
          下载模版
        </el-button>
        <el-button @click="exportData($event)" v-if="data.authMenus.export" type="primary">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" border style="width: 96%; margin-left: 2%;" :emptyText="emptyTableText">
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="month" label="月份" />
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="storeCode" label="门店编号" />
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="storeName" label="门店名称" />
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="totalCost" label="成本合计" />

      <el-table-column align="center" show-overflow-tooltip width="100px" prop="goodsCost" label="1商品成本" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="260px"
        prop="yjCost"
        label="1.1洋酒成本（扣减二维码回收后）"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="hjCost" label="1.2红酒成本" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="xyCost" label="1.3香烟成本" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="260px"
        prop="pjCost"
        label="1.4啤酒成本（扣减空瓶回收后）"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="xcCost" label="1.5小吃冷饮成本" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="otherCost" label="1.6其它成本" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="150px"
        prop="otherBusinessCost"
        label="2其他业务支出"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="headerCost" label="3总部费用" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="fzCost" label="4房租物业费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="storeCost" label="4.1门店房租" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="marketCost" label="4.2商场物业推广" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="userCost" label="5人工成本" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="userSalaryCost" label="5.1职工薪酬" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="userBonusCost" label="5.2奖金" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="userWelfareCost" label="5.3福利费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="businessCost" label="6运营费用" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="260px"
        prop="softCost"
        label="6.1软件版权费（曲库费）"
      />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="260px"
        prop="softYearCost"
        label="6.1软件版权费（软件年费摊销）"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="fixCost" label="6.2安装维修费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="outCost" label="6.3外场用品费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="kitchenCost" label="6.4厨房用品费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="barCost" label="6.5吧台用品费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="securityCost" label="6.6安保劳务费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="waterCost" label="7水电煤费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="electricityCost" label="7.1电费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="marketOutCost" label="8营销费用" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="inAdsCost" label="8.1线下广告费" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="200px"
        prop="outAdsCost"
        label="8.2年度线上推广费分摊"
      />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="210px"
        prop="adsDailyCost"
        label="8.3日常线上团购广告服务费"
      />
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="materialCost" label="8.4物料费" />
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="managerCost" label="9管理费用" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="150px"
        prop="internetCost"
        label="9.1网络通讯宽带费"
      />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="150px"
        prop="businessInsuranceCost"
        label="9.2商业保险"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="officeCost" label="9.3办公费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="trafficCost" label="9.4交通差旅费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="carCost" label="9.5车辆使用费" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="zhaoDaiCost" label="9.6招待费" />
      <el-table-column
        align="center"
        show-overflow-tooltip
        width="180px"
        prop="secretCustomerCost"
        label="9.7神秘顾客费用"
      />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="financeCost" label="10财务费用" />
      <el-table-column align="center" show-overflow-tooltip width="150px" prop="tuanGouCost" label="10.1团购手续费" />
      <el-table-column align="center" show-overflow-tooltip width="180px" prop="leiShiCost" label="10.2雷石/可即手续费">
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip width="100px" prop="taxCost" label="11税费" />

      <el-table-column align="center" show-overflow-tooltip width="120px" prop="updateUserName" label="更新人" />
      <el-table-column align="center" show-overflow-tooltip width="120px" prop="updateTime" label="更新时间" />
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { getToken } from '@/lin/util/token'
import { post } from '@/lin/plugin/axios'
import clearSelect from '@/lin/util/clearSelect2.js'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, onMounted, ref, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import Api from '@/api/index.js'
import { createMoveBall } from '@/utils/createMoveBall.js'
const uploadAction = process.env.VUE_APP_BASE_URL + '/shadow/export/upload'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}
const props = defineProps({
  auths: {},
})
const data = reactive({
  storeCode: [],
  time: dayjs()
    .subtract(1, 'month')
    .format('YYYY-MM'),
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  regionCode: [],
  areaCode: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  authMenus: {},
})

const route = useRoute()

onMounted(async () => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getStores()
  getAreas()
})
const downTemplate = () => {
  ElMessageBox.confirm(
    '<p>导入说明（必读）:</p><p>1、月份格式举例：2024-10；</p><p>2、门店编号英文大写，门店名称需要与系统中的名称保持一致；</p><p>3、金额字段需要为保留两位小数的数字格式（模板已设置）；</p><p>4、模板中示例数据需要删除，导入的列项必须与模板中的列项一致。</p>',
    '下载模板',
    {
      confirmButtonText: '下载模板',
      cancelButtonText: '取消',
      dangerouslyUseHTMLString: true,
    },
  ).then(async () => {
    //创建a标签下载文件
    let a = document.createElement('a')
    a.href = `https://oss-excel-template.oss-cn-hangzhou.aliyuncs.com/02%E6%88%90%E6%9C%AC%E9%A1%B9%EF%BC%88%E6%A8%A1%E6%9D%BF%EF%BC%89.xlsx`
    a.download = '成本导入模板.xlsx'
    a.click()
  })
}
const handleSuccess = () => {
  ElMessage.success('上传成功')
  searchLists()
}
const handleError = err => {
  ElMessage.error(err || '上传失败')
}
const getStores = async () => {
  let res = await API.getStoreListByName({})
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.time = dayjs()
    .subtract(1, 'month')
    .format('YYYY-MM')
  data.pageSize = 10
  data.pageNum = 1
  data.storeCode = []
  data.regionCode = []
  data.areaCode = []
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  searchLists()
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

const exportData = async event => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  if (!data.time) {
    return ElMessage.warning('请选择查询时间')
  }
  data.fullscreenLoading = true

  let model = {}

  model.month = data.time
  ;(model.regions = data.areaCode), (model.stores = data.storeCode)
  model.pageSize = data.pageSize
  model.pageNum = data.currentPage

  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=COST_DETAILS`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

const emptyTableText = ref('请先设置查询条件，再点击查询数据')
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  if (!data.time) {
    return ElMessage.warning('请选择查询时间')
  }
  let params = {
    stores: data.storeCode,
    regions: data.areaCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }
  params.month = data.time
  data.fullscreenLoading = true
  params = generateParam(params)

  //占坑
  data.tableData = []

  getTableData(params, 'COST_DETAILS')

  // if (params.pageNum > 1) {
  //   return data.tableData.splice(0, 1, data.totalRowData)
  // }
  // getTotalRowRequest(params, 'COST_DETAILS')
}

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData.push(...(res.data.items ?? []))
    data.total = res.data.total
    emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
  } else {
    data.tableData = []
    data.total = 0
  }
}

/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    data.totalRowData = res.data ?? {}
    data.tableData.splice(0, 1, data.totalRowData)
  } else {
    data.tableData.splice(0, 1)
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__header-wrapper .el-table__header div {
  text-align: center;
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
</style>
